import { createTheme } from "@mantine/core"

export const theme = createTheme({
  primaryColor: "green",
  colors: {
    // You can customize color palette here
    green: [
      "#E8F5E9", // 0
      "#C8E6C9", // 1
      "#A5D6A7", // 2
      "#81C784", // 3
      "#66BB6A", // 4
      "#4CAF50", // 5
      "#43A047", // 6
      "#388E3C", // 7
      "#2E7D32", // 8
      "#1B5E20", // 9
    ],
  },
  fontFamily: "Nunito, sans-serif",
  fontSizes: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "1.75rem",
  },
  headings: { fontFamily: "Nunito, sans-serif" },
})
